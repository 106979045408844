<template>
    <div class="decoratePlan">
        <rxNavBar title="装配方案"></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <van-tabs v-model="outerActive"
                  line-width="20"
                  line-height="5"
                  title-active-color="rgb(255,81,45)"
                  sticky
                  offset-top="1.2195121951219512rem">
            <van-tab title="详情">
                <p class="details" style="margin-left: 15px">合同详情</p>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info" tyoe="flex">
                            <van-col span="24">
                                <p>地址：<span>{{ contract.adress }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>合同编号：<span style="color: #EB856F">{{ contract.contractNo }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>业主：<span>{{ contract.theOwner }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>面积：<span>{{ contract.area }}㎡</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>收房年限：<span>{{ contract.fixedNumberOfYears }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                <p class="details01">申请详情</p>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info">
                            <van-col span="12">
                                <p>申请人：<span>{{ apply.principal }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>联系方式：<span>{{ apply.phone }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>费用承担方：<span>{{ apply.costBearStr }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>负责人：<span>{{ apply.chargeGlobaluserName }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>设计师：<span>{{ apply.theDesigner }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>类型：<span>{{ apply.housingType }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>装修预算：<span><font color="#EB856F">{{ apply.theBudget }}元</font></span></p>
                            </van-col>
                            <van-col span="12">
                                <p>交房日期：<span>{{ apply.dataHome }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>装修需求：<span>{{ apply.demand }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>装修描述：<span>{{ apply.describe }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                <div v-if="imagesList.length" class="potential" style="margin-bottom: -20px">
                    <!-- 图片墙 -->
                    <div class="panel withMargin" style="width: 345px;height: 105px">
                        <div class="swiper_show" ref="wrapperBox">
                            <div class="content">
                                <div class="imgWrap" style="width: 100px;height: 79px" v-for="(url,i) in imagesList"
                                     @click="previewImg(i)">
                                    <van-image fit="contain" width="100" height="75" radius="10" :src="url"/>
                                </div>
                                <div class="numb">
                                    {{ imagesList.length }}图
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="details01" style="margin-bottom: -15px">设计图片</p>
                <div class="part" style="margin-top: 5px;margin-bottom: 0px" >
                    <div class="part-inputpart">
                        <div class="part-uploadpic-content">
                            <!--上传图片按钮-->
                            <van-uploader v-model="contractInfo.attachmentList" multiple
                                          upload-icon="plus"
                                          accept="image/*"
                                          :max-count="50"
                                          :after-read="afterReadTest"
                                          image-fit="contain"

                            >
                            </van-uploader>
                        </div>
                    </div>
                </div>
                <p class="details01" style="margin-bottom: -15px">视频</p>
                <div class="part" style="margin-top: 5px;margin-bottom: 0px" >
                    <div class="part-inputpart">
                        <div class="part-uploadpic-content">
                            <!--上传图片按钮-->
                          <van-uploader v-model="videoData" multiple
                          class="upLoadImg"
                          :max-count="5"
                          accept="video/*"
                          preview-size="93px"
                          :after-read="afterReadTestVideo" >
                          </van-uploader>
            <div v-for="item,index in videoList" :key="index" style="display:inline-block;margin:0 10px;position:relative">
              <video
                style="width:93px;height:93px;object-fit:cover;position:relative"    controls="controls" loop="loop"
                :src="item.path"
              ></video>
              <span class="remove_video"  @click="removeVideo(index)" style="position:absolute;;right:10px;font-size:18px">X</span>

            </div>
                        </div>
                    </div>
                </div>
                <div style="margin:10px 0;">
                    <span class="part-subtitle">装修方案</span>
                    <div @click="lookOwner()" class="bottom-property">查看业主物业交割</div>
                    <div @click="roomRegionChange()" class="bottom-property2">房间结构调整</div>
                </div>
                <div class="innerTabs">
                    <van-tabs v-model="innerActive"
                              line-width="20"
                              line-height="5"
                              title-active-color="rgb(255,81,45)"
                              swipeable
                              sticky
                              offset-top="1.2195121951219512rem"
                    >
                        <van-tab title="硬装方案">
                            <div class="part-inputpart">
                                <div id="partnershipProportionPanel" class="part-inputpart-row"
                                     @click="hardDisabled?'':isPartnershipProportionShow = !isPartnershipProportionShow">
                                    <span :class=" contractInfo.partnershipProportion.userName ? 'content-have' : 'content-none' "></span>
                                    <span class="part-inputpart-row-header">负责人</span>
                                    <span class="content-divide">|</span>
                                    <span class="part-inputpart-row-graytext"
                                          :class="contractInfo.partnershipProportion.userName ? 'part-inputpart-row-normaltext':'' ">{{contractInfo.partnershipProportion.userName}}</span>
                                    <img class="part-inputpart-row-right"
                                         :class=" isPartnershipProportionShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                                         src="../../assets/images/triangle.png">
                                </div>
                                <div id="StuffTime" class="part-inputpart-row"
                                     @click="hardDisabled?'':isStuffTimeShow = !isStuffTimeShow">
                                    <span :class=" !contractInfo.stuffTime ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">开始时间</span>
                                    <span class="content-divide">|</span>
                                    <span class="part-inputpart-row-graytext"
                                          :class="0==contractInfo.stuffTime ? '': 'part-inputpart-row-redtext' ">{{contractInfo.stuffTime | formatDateTime}}</span>
                                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                         src="../../assets/images/timepick.png">
                                </div>
                                <div>
                                    <van-popup v-model="isStuffTimeShow" position="bottom">
                                        <van-datetime-picker
                                                v-model="currentDate"
                                                type="date"
                                                title="选择年月日"
                                                item-height="35px"
                                                @confirm="StuffTimeConfirm"
                                                @cancel="StuffTimeCancel"
                                        />
                                    </van-popup>
                                </div>
                                <div class="part-inputpart-row">
                                    <span :class=" !ownerInfo.langTime? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">预计时长</span>
                                    <span class="content-divide">|</span>
                                    <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="4"
                                           v-model="ownerInfo.langTime" :readonly="hardDisabled" placeholder="">
                                    <span class="part-inputpart-row-right"><font color="#E4E4E4">天</font></span>
                                </div>
                                <div class="part-inputpart-row">
                                    <div :class=" !endTimes ? 'content-none' : 'content-have'"></div>
                                    <span class="part-inputpart-row-header">结束时间</span>
                                    <span class="content-divide">|</span>
                                    <span>{{ endTimes | formatDateTime }}</span>
                                </div>
                                <div class="part-inputpart-row">
                                    <span :class=" ownerInfo.firstPeriod==='' ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">首期占比</span>
                                    <span class="content-divide">|</span>
                                    <input type="tel"
                                           oninput="value=value.replace(/[^\d]/g,'')" maxlength="3"
                                           v-model="ownerInfo.firstPeriod" :readonly="hardDisabled" placeholder="请输入">
                                    <span class="part-inputpart-row-right"><font color="#E4E4E4">%</font></span>
                                </div>
                                <div class="shit" style="margin-top: 0px">
                                    <div class="panel-fuck">
                                        <div class="panel-fuck">
                                            <div  class="" v-for="(item,index) in roomData" :key="index">
                                               <div class="part-inputpart-row" @click="decorateList(item,0)">
                                    <span :class=" roomHardData[index]=='0' ? 'content-none' : 'content-have' " ></span>
                                    <span class="part-inputpart-row-header">{{item.roomName}}</span>

                                    <span style="flex: auto"></span>
                                    <div v-for="(room,index) in roomCountList" :key="index">
                                        <span class="part-inputpart-row" style="position:relative;right:10px" v-if="item.id==room.roomHouse_id&&room.itemType=='0'">{{room.count?room.count:0}}项 / {{room.money?room.money:0}}元</span>
                                        <!--<span class="part-inputpart-row" v-else-if="item.id!=room.roomHouse_id&&roomCountList.length==(index+1)&&room.itemType=='0'">0项 / 0元</span>-->
                                    </div>
                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-tab>
                        <van-tab title="软装方案">
                            <div class="part-inputpart">
                                <div id=" signatoryPanel" class="part-inputpart-row"
                                     @click="softDisabled?'':isSignatoryShow = !isSignatoryShow"
                                     style="border-radius:0px;">
                                    <span :class=" contractInfo.signatory.userName ? 'content-have' : 'content-none' "></span>
                                    <span class="part-inputpart-row-header">负责人</span>
                                    <span class="content-divide">|</span>
                                    <span class="part-inputpart-row-graytext"
                                          :class="contractInfo.signatory.userName ? 'part-inputpart-row-normaltext':'' ">{{
                      contractInfo.signatory.userName
                    }}</span>
                                    <img class="part-inputpart-row-right"
                                         :class=" isSignatoryShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                                         src="../../assets/images/triangle.png">
                                </div>
                                <div id="checkInDatePanel" class="part-inputpart-row"
                                     @click="softDisabled?'':isCheckInDateShow = !isCheckInDateShow">
                                    <span :class=" !contractInfo.checkInDate ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">开始时间</span>
                                    <span class="content-divide">|</span>
                                    <span class="part-inputpart-row-graytext"
                                          :class="0==contractInfo.checkInDate ? '': 'part-inputpart-row-redtext' ">{{
                      contractInfo.checkInDate | formatDateTime
                    }}</span>
                                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                                         src="../../assets/images/timepick.png">
                                </div>
                                <div>
                                    <van-popup v-model="isCheckInDateShow" position="bottom">
                                        <van-datetime-picker
                                                v-model="currentDate"
                                                type="date"
                                                title="选择年月日"
                                                item-height="35px"
                                                @confirm="checkInDateConfirm"
                                                @cancel="checkInDateCancel"
                                        />
                                    </van-popup>
                                </div>
                                <div class="part-inputpart-row">
                                    <span :class=" !ownerInfo.startTime ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">预计时长</span>
                                    <span class="content-divide">|</span>
                                    <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="4"
                                           v-model="ownerInfo.startTime" :readonly="softDisabled"
                                           placeholder="">
                                    <span class="part-inputpart-row-right"><font color="#E4E4E4">天</font></span>
                                </div>
                                <div class="part-inputpart-row">
                                    <div :class=" !endingTime ? 'content-none' : 'content-have'"></div>
                                    <span class="part-inputpart-row-header">结束时间</span>
                                    <span class="content-divide">|</span>
                                    <span>{{ endingTime | formatDateTime }}</span>
                                </div>
                                <div class="part-inputpart-row">
                                    <span :class=" ownerInfo.firstPeriods==='' ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">首期占比</span>
                                    <span class="content-divide">|</span>
                                    <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" maxlength="3"
                                           v-model="ownerInfo.firstPeriods" :readonly="softDisabled" placeholder="请输入">
                                    <span class="part-inputpart-row-right"><font color="#E4E4E4">%</font></span>
                                </div>
                                <div class="shit">
                                    <div class="panel-fuck" style="margin-top: 0px">
                                        <div class="panel-fuck">
                                            <div v-for="item,index in roomData" :key="item.id">
                                               <div class="part-inputpart-row" @click="decorateList(item,1)">
                                    <span :class=" roomSoftData[index]=='0' ? 'content-none' : 'content-have' " ></span>
                                    <span class="part-inputpart-row-header">{{item.roomName}}</span>

                                    <span style="flex: auto"></span>
                                    <div v-for="(room,index) in roomCountList" :key="index">
                                        <span class="part-inputpart-row" v-if="item.id==room.roomHouse_id&&room.itemType=='1'">{{room.count?room.count:0}}项 / {{room.money?room.money:0}}元</span>
                                    </div>
                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>
                <!--选择项和填写项不为空才能点击，否则disable-->
                <van-button
                        v-if="2==afterRentStatus"
                        :class="toSaveAble ? 'saveButton_Disable':'saveButton_Enable'"
                        :disabled="toSaveAble || enable"

                        @click="saveInfo(1)"
                        style="margin-bottom: 0px"
                >
                    保存
                </van-button>


                <p class="details02" style="margin-left: 15px;margin-bottom: 5px">费用情况</p>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info" tyoe="flex">
                            <van-col span="12">
                                <p>硬裝预计：<span style="font-size: 14px">{{ hardCost }}元</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>硬裝首期：<span style="font-size: 14px">{{ isno=='1'?0:(hardCost * ownerInfo.firstPeriod / 100).toFixed(2) }}元</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>软裝预计：<span style="font-size: 14px">{{ softCost }}元</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>软裝首期：<span style="font-size: 14px">{{ (softFirstCost * ownerInfo.firstPeriods / 100).toFixed(2)  }}元</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>总金额：<span style="font-size: 14px">{{ (Number(softCost) + Number(hardCost)).toFixed(2) }}元</span>
                                </p>
                            </van-col>

                        </van-row>
                    </div>
                </div>

                <!--选择项和填写项不为空才能点击，否则disable-->
                <van-button
                        v-if="2==afterRentStatus"
                        :class="saveAble ? 'saveButton_Disable':'saveButton_Enable'"
                        :disabled="saveAble || enable"
                        @click="saveInfo(0)"
                >
                    完成
                </van-button>
                <van-button
                        v-if="3==afterRentStatus"
                        :class="saveButton_Enable"
                        @click="saveInfo(1)"
                        :disabled="enable"
                >
                    保存
                </van-button>
            </van-tab>
            <van-tab title="账单">
                <van-row style="margin-top: 10px"></van-row>
                <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            :finished-text="noContent ? '' : '已经到底了'"
                            @load="onLoad"
                    >
                        <div class="panelBill">
                            <div v-for="body in billList">
                                <div style="width: 50%;float: left;padding: 16px 0px 3px 14px"><span
                                        style="font-weight: 900;font-size: 15px">{{ body.billFeeType }}</span></div>
                                <div style="text-align: right;padding: 16px 14px 3px 0px;position: relative;overflow: hidden">
              <span
                      style="font-weight: 900;font-size: 15px;color: #fe6641">{{
                  body.totalAmount > 0 ? '-' : ''
                }}{{ body.totalAmount }}</span>
                                </div>
                                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px"
                                     v-show="body.billDescription">
                                    <span style="font-size: 10px;color: #9d9d9d">{{ body.billDescription }}</span>
                                </div>
                                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" class="bordBottom">
                                    <span style="font-size: 10px;color: #9d9d9d">{{ body.addTime }}</span>
                                </div>
                            </div>
                        </div>
                    </van-list>
                    <common-empty v-if="noContent"></common-empty>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>


        <!--点击编辑弹出部分-->
        <div class="popup">
            <!--&lt;!&ndash;        点击1显示项目详情和装修区域的大弹窗&ndash;&gt;-->
            <!--<div style="color: #ff5809" @click="big()">点击1</div>-->
            <!--&lt;!&ndash;        点击1显示项目详情的小弹窗&ndash;&gt;-->
            <!--<div style="color: #ff5809" @click="bigTwo()">点击2</div>-->

            <!--大弹窗-->
            <van-popup v-if="allSoftList[softIndex]" v-model="show" class="van-popup--bottomOne" position="bottom"
                       @click-overlay="closeSoft"
                       :style="{ height: '70%' }">
                <div class="titleOne">项目详情</div>
                <div class="normalBlock" style="margin-bottom: 0">
                    <span
                            :class="allSoftList[softIndex].children[softI].budget ? 'content-have':'content-none'"></span>
                    <span class="blockTitle">名称</span>
                    <span class="content-divide-big">|</span>
                    <span
                            style="font-size: 15px;font-weight: 900">{{ allSoftList[softIndex].children[softI].itemName }}</span>
                </div>
                <!--        <div class="normalBlock">-->
                <!--                    <span v-if="allSoftList[softIndex]"-->
                <!--                          :class="allSoftList[softIndex].children[softI].budget ? 'content-have':'content-none'"></span>-->
                <!--          <span class="blockTitle">费用</span>-->
                <!--          <span class="content-divide-big">|</span>-->
                <!--          <input v-if="allSoftList[softIndex]" type="tel" class="inputPrice"-->
                <!--                 oninput="value=value.replace(/[^\d.]/g,'')"-->
                <!--                 v-model="allSoftList[softIndex].children[softI].budget">-->
                <!--          <span class="priceUnit">元</span>-->
                <!--        </div>-->
                <div class="titleOne">区域分配</div>
                <div v-if="allSoftList[softIndex].children[softI]">
                    <div style="margin-bottom: 20px" v-if="item.subType != '10' &&
                                                ((allSoftList[softIndex].children[softI].gacid.toString() == broadbandClassification && item.id == -1) ||
                                                (allSoftList[softIndex].children[softI].gacid.toString() == smartDevicesClassification &&
                                                ((item.id == -1 &&
                                                ((allSoftList[softIndex].children[softI].id.toString() == smartDevicesLock && mensuo == 'N') || (allSoftList[softIndex].children[softI].id.toString() == smartDevicesWatt && dianbiao == 'N') || (allSoftList[softIndex].children[softI].id.toString() == smartDevicesGateway))) ||
                                                (item.id != -1 && allSoftList[softIndex].children[softI].id.toString() == smartDevicesInnerLock) ||
                                                (allSoftList[softIndex].children[softI].id.toString() != smartDevicesGateway && allSoftList[softIndex].children[softI].id.toString() != smartDevicesWatt && allSoftList[softIndex].children[softI].id.toString() != smartDevicesLock && allSoftList[softIndex].children[softI].id.toString() != smartDevicesInnerLock))) ||
                                                ((allSoftList[softIndex].children[softI].gacid.toString() != broadbandClassification && allSoftList[softIndex].children[softI].gacid.toString() != smartDevicesClassification)))"
                         v-for="(item,index) in allSoftList[softIndex].children[softI].roomList" :key="index">
                        <div class="this-div">
                            <span class="blockTitle" style="margin:0 10px 0 20px">{{ item.roomName }}</span>
                            <span class="content-divide-big">|</span>
                            <div style="margin-left: auto">
                                <!--点击数字减少-->
                                <div @click="cut(index)" class="numDiv">
                                    <!--<img class="numCutAndAdd" src="../../assets/images/grayAdd.png">-->
                                    <span style="color:#DCDCDC">-</span>
                                </div>
                                <span
                                        class="num">{{ allSoftList[softIndex].children[softI].roomList[index].count }}</span>
                                <!--点击数字增加-->
                                <div @click="add(index)" class="numDiv" style="margin-right: 30px">
                                    <!--<img class="numCutAndAdd" src="../../assets/images/grayAdd.png">-->
                                    <span style="color: #DCDCDC">+</span>
                                </div>
<!--                                <van-field @blur="changeSoftBudget(index)" class="num" style="width:40%;height: 55px;border-radius: 10px"  v-model="allSoftList[softIndex].children[softI].roomList[index].count" type="digit"/>-->
                            </div>
                        </div>
                        <div class="this-div">
                            <!--              <span v-if="allSoftList[softIndex]"-->
                            <!--                    :class="allSoftList[softIndex].children[softI].budget ? 'content-have':'content-none'"></span>-->
                            <span class="blockTitle" style="margin-left: 20px">费用</span>
                            <span class="content-divide-big">|</span>
                            <input type="tel" class="inputPrice"
                                   oninput="value=value.replace(/[^\d.]/g,'')"
                                   v-model="allSoftList[softIndex].children[softI].roomList[index].budget">
                            <span class="priceUnit">元</span>
                        </div>
                        <!--供应商-->
                        <div class="this-div">
                            <span class="blockTitle" style="margin-left: 20px">供应商</span>
                            <span class="content-divide-big">|</span>
                            <span class="priceUnit" @click="selectSupplierList(index)">{{allSoftList[softIndex].children[softI].roomList[index].supplierName?allSoftList[softIndex].children[softI].roomList[index].supplierName:'供应商'}}</span>
                        </div>
                        <van-popup v-model="showSupplier" position="bottom">
                          <van-picker
                            show-toolbar
                            value-key="supplierName"
                            :columns="supplierList"
                            @cancel="showSupplier = false"
                            @confirm="selectSupplierOption"
                          />
                       </van-popup>
                        <div class="this-div">
                            <span class="blockTitle" style="margin-left: 20px">备注</span>
                            <span class="content-divide-big">|</span>
                            <input  class="inputPrice"
                                   v-model="allSoftList[softIndex].children[softI].roomList[index].remarks">
                        </div>
                    </div>
                </div>

                <van-button
                        class="saveButton_Enable"
                        style="margin-top: 10px"
                        @click="show = false"
                >
                    保存
                </van-button>
            </van-popup>

            <!--小弹窗-->
            <van-popup v-if="allHardList[hardIndex]" v-model="showTwo" class="van-popup--bottomTwo" position="bottom"
                       @click-overlay="closeHard"
                       :style="{ height: '40%' }">
                <div class="titleOne">项目详情</div>
                <!--名称选择项-->
                <div class="part-inputpart-row-big">
                    <span
                            :class="allHardList[hardIndex].children[hardI].count ? 'content-have' : 'content-none'"></span>
                    <span class="part-inputpart-row-header-big">名称</span>
                    <span class="content-divide-big">|</span>
                    <span>{{ allHardList[hardIndex].children[hardI].itemName }}</span>
                </div>
                <!--点击数字减少-->
<!--                <div @click="cutTwo" class="numDiv">-->
<!--&lt;!&ndash;                    <img class="numCutAndAdd" src="../../assets/images/grayAdd.png">&ndash;&gt;-->
<!--                    <span style="color:#DCDCDC">-</span>-->
<!--                </div>-->
<!--                <span class="num">{{ allHardList[hardIndex].children[hardI].count }}</span>-->
<!--                <input class="num" v-model="allHardList[hardIndex].children[hardI].count">-->
                <div style="float:left;font-size: 0.4rem;font-weight: 900; color: black; margin: 16px 5px 0 5px">数量</div>
                <van-field @blur="changeBudget()" class="num" style="width:26.5%;height: 1.333rem;border-radius: 10px"  v-model="allHardList[hardIndex].children[hardI].count" type="number"/>
                <!--点击数字增加-->
<!--                <div @click="addTwo" class="numDiv">-->
<!--                    &lt;!&ndash;<img class="numCutAndAdd" src="../../assets/images/grayAdd.png">&ndash;&gt;-->
<!--                    <span style="color: #DCDCDC">+</span>-->
<!--                </div>-->
                <!--费用填写框-->
                <div class="normalBlock">
                    <span
                            :class="allHardList[hardIndex].children[hardI].count ? 'content-have' : 'content-none'"></span>
                    <span class="blockTitle">费用</span>
                    <span class="content-divide-big">|</span>
                    <input type="tel"
                           oninput="value=value.replace(/[^\d.]/g,'')"
                           class="inputPrice"
                           v-model="allHardList[hardIndex].children[hardI].budget">
                    <span class="priceUnit">元</span>
                </div>
                <div class="normalBlock">
                    <span
                            :class="allHardList[hardIndex].children[hardI].count ? 'content-have' : 'content-none'"></span>
                    <span class="blockTitle">备注</span>
                    <span class="content-divide-big">|</span>
                    <input
                           class="inputPrice"
                           v-model="allHardList[hardIndex].children[hardI].remarks">
                </div>
                <!--选择项和填写项不为空才能点击，否则disable-->
                <van-button
                        class="saveButton_Enable"
                        style="margin-top: 0"
                        @click="showTwo = false"
                >
                    保存
                </van-button>
            </van-popup>
        </div>
        <!--      硬装负责人选择-->
        <van-popup v-model="isPartnershipProportionShow" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="signatoryArr"
                    @cancel="isPartnershipProportionShow = false"
                    :default-index="hardChargeIndex"
                    value-key="userName"
                    @confirm="selectPartnershipProportionOption"
            />
        </van-popup>
        <!--      软装负责人选择-->
        <van-popup v-model="isSignatoryShow" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="signatoryArr"
                    @cancel="isSignatoryShow = false"
                    :default-index="softChargeIndex"
                    value-key="userName"
                    @confirm="selectSignatoryOption"
            />
        </van-popup>

    </div>
</template>

<script>
    import BScroll from 'better-scroll';
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import {
        /*NavBar,
  DatetimePicker,
  Uploader,*/
        NavBar,//标题
        //合同详情、申请详情
        Tab,
        Tabs,
        Row,
        Col,
        DropdownMenu,
        DropdownItem,
        Image as VanImage,
        Uploader,//上传图片参数
        //tab部分
        Picker,
        Popup,
        Field,
        ImagePreview,
        //tab关联部分
        DatetimePicker,
        List,
        Tag,
        //点击弹出部分
        Button,
        Overlay,
        Collapse,
        CollapseItem,
        Toast, PullRefresh
    } from 'vant'
    import * as OSS from 'ali-oss'
    import {getStaffId, globaluserId, responseUtil} from "../../libs/rongxunUtil";
    import {
        base64Upload, browseBillList,
        returnChargeAndDesignerList,
        showProgramme,
        updateRenovationProgramme,querySupplierList,queryRoom,updateVideo,queryisPament
    } from "../../getData/getData";
    import eventBus from "../../components/rongxun/rx-navBar/envbus.js"
    export default {

        name: 'decoratePlan',

        computed: {
            toSaveAble() {
                let that=this
                if(that.$route.query.isFirst=='0'){
                    return false
                }
                if (that.ownerInfo.langTime && that.ownerInfo.startTime && that.contractInfo.signatory.id
                    && that.contractInfo.partnershipProportion.id  && that.contractInfo.stuffTime
                    && that.endTimes && that.contractInfo.checkInDate && that.endingTime && that.ownerInfo.firstPeriod!=='' && that.ownerInfo.firstPeriods!=='')
                    return false
                else
                    return true
            },
            saveAble() {

                let that=this
                if(that.$route.query.isFirst=='0'){
                    return false
                }
                /*for(let i in that.roomHardData){
                    if(that.roomHardData[i]=='0'){
                        return true
                    }
                }
                for(let i in that.roomSoftData){
                    if(that.roomSoftData[i]=='0'){
                        return true
                    }
                }*/
                if (that.ownerInfo.langTime && that.ownerInfo.startTime && that.contractInfo.signatory.id
                    && that.contractInfo.partnershipProportion.id  && that.contractInfo.stuffTime
                    && that.endTimes && that.contractInfo.checkInDate && that.endingTime && that.ownerInfo.firstPeriod!=='' && that.ownerInfo.firstPeriods!==''){
                    return false}
                else{
                    return true}
            },
            endTimes() {
                if (this.contractInfo.stuffTime && this.ownerInfo.langTime) {
                    let begintime = new Date(this.contractInfo.stuffTime)
                    let add = parseInt(this.ownerInfo.langTime) - 1
                    return begintime.setDate(begintime.getDate() + add)
                }
            },
            endingTime() {
                if (this.contractInfo.checkInDate && this.ownerInfo.startTime) {
                    let begintime = new Date(this.contractInfo.checkInDate)
                    let add = parseInt(this.ownerInfo.startTime) - 1
                    return begintime.setDate(begintime.getDate() + add)
                }
            },
            hardCost() {
                let hardcost = 0
                for(let i in this.roomCountList){
                   if(this.roomCountList[i].itemType=='0'){
                     hardcost += Number(this.roomCountList[i].money)
                   }
                }
                return hardcost.toFixed(2)
            },
            /*softCost() {
                let softcost = 0
                for(let i in this.roomCountList){
                   if(this.roomCountList[i].itemType=='1'){
                     softcost += Number(this.roomCountList[i].money)
                   }
                }
                return softcost.toFixed(2)
            },*/
        },

        components: {
            /*[NavBar.name]: NavBar,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,*/
            [NavBar.name]: NavBar,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Row.name]: Row,
            [Col.name]: Col,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [VanImage.name]: VanImage,
            [Uploader.name]: Uploader,//上传图片参数
            //tab部分
            [Popup.name]: Popup,
            [Field.name]: Field,
            [ImagePreview.Component.name]: ImagePreview.Component,
            //tab关联部分
            [DatetimePicker.name]: DatetimePicker,
            [List.name]: List,
            [Tag.name]: Tag,
            //点击弹出部分
            [Button.name]: Button,
            [Overlay.name]: Overlay,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Toast.name]: Toast,
            [Picker.name]: Picker,
            [PullRefresh.name]: PullRefresh,
            rxNavBar,
        },

        data() {
            return {
                outerActive: 0,
                innerActive: 0,
                isLoading: false,
                loading: false,
                finished: false,
                noContent: false,
                page: 1,
                billList: [],
                loadingFlag: false,//上传图片loading
                hardChargeIndex: 0,
                softChargeIndex: 0,

                afterRentStatus: '',

                rtid: this.$route.query.rtid || '',

                //临时保存
                custody: {},

                allHardList: [],
                hardIndex: 0,
                hardI: 0,
                allSoftList: [],
                softIndex: 0,
                softI: 0,
                roomList: [],
                hardDisabled: false,
                softDisabled: false,

                //点击编辑弹出的页面的参数
                show: false, //大弹框为隐藏状态
                showTwo: false, //小弹窗为隐藏状态
                isNameSelect: false, //大弹窗的选择名称是未选择状态
                isNameSelectTwo: false, //小弹窗的选择名称是未选择状态
                isNameShow: false, //大弹窗备选名称显示
                isNameShowTwo: false, //小弹窗备选名称显示
                guidancePrice: '', //大弹窗的费用填写
                guidancePriceTwo: '', //小弹窗的费用填写
                selectName: '', //大弹窗的选择名称
                selectNameTwo: '', //小弹窗的选择名称

                hardActiveNames: '',
                softActiveNames: '',

                //详情部分：
                /* 合同详情参数 */
                contract: {
                    adress: '',
                    contractNo: '',
                    area: '',
                    theOwner: '',
                    fixedNumberOfYears: ''
                },
                /*预计费用*/
                expect: {
                    stuff: '',
                    Soft: '',
                    total: ''


                },
                /* 申请详情参数 */
                apply: {
                    principal: '',
                    phone: '',
                    theDesigner: '',
                    housingType: '',
                    theBudget: '',
                    dataHome: '',
                    describe: '',
                    demand: '',
                    chargeGlobaluserName:'',
                    costBearStr:''
                },
                //实勘图片
                imagesList: [],

                //显示 哪个信息
                whichInfo: '0',
                //入住日期
                isCheckInDateShow: false,
                isStuffTimeShow: false,
                currentDate: new Date(),
                //签约人
                isSignatoryShow: false,
                isSignatorySelected: false,
                //合伙占比
                isPartnershipProportionShow: false,
                isPartnershipProportionSelected: false,
                ownerInfo: {
                    //预计时长
                    langTime: '',
                    startTime: '',
                    //结束时间
                    endTimes: '',
                    endingTime: '',
                    //首期占比
                    firstPeriod: '',
                    firstPeriods: '',
                    //预计费用
                    estimatedCost: '',
                    estimatedCosts: '',
                    name: '',
                    sex: '',
                    card: '',
                    cardNumber: '',
                    emergencyContactName: '',
                    emergencyContactPhone: '',
                    CertificateFront: '',
                    CertificateBack: '',
                    CertificateHandHeld: '',
                    //是否有同住
                    liveTogether: ''
                },
                contractInfo: {
                    //首期硬装费用
                    hardCost: '',
                    hardCosts: '',
                    //预计合计费用
                    estimatedCost: '',
                    estimatedCosts: '',
                    //硬装开始时间
                    stuffTime: '',
                    //首期硬装费用
                    money: '',
                    //合同编号
                    number: 'CA123123456',
                    //合同类型
                    type: '新签',
                    cutOffDate: '2020-12-21',
                    //付款方式
                    paymentMethod: '',
                    //租金
                    rent: '',
                    //押金
                    deposit: '',
                    // 租期
                    leaseTerm: '',
                    // 开始期限
                    startPeriod: '',
                    // 结束期限
                    endPeriod: '',
                    // 入住日期
                    checkInDate: '',
                    //优惠活动
                    discounts: '',
                    //补充说明
                    explanationDescribe: '',
                    //附件
                    attachmentList: [],
                    //签约人
                    signatory: {},
                    //合伙人
                    partner: '',
                    //合伙占比
                    partnershipProportion: {}
                },
                /*责任人*/
                signatoryArr: [],

                //9.4新增
                broadbandClassification: '',//宽带智能设备分类id
                smartDevicesClassification: '',//智能设备分类id
                smartDevicesInnerLock: '',//内门锁
                smartDevicesLock: '',//门锁
                smartDevicesWatt: '',//电表
                smartDevicesGateway: '',//网关
                mensuo: '',//共区有无插排
                dianbiao: '',//共区有无电表
                showSupplier:false,   //显示供应商列表
                supplierList:[],      //供应商列表
                index:'',
                selectedSoftMap:{},
                roomData:[],    //有哪几间房屋
                roomCountList:[],   //
                videoList:[],    //视频
                videoData:[],
                roomHardData:[],    //硬装必填项
                roomSoftData:[],    //软装必填项
                isno:'',
                softCost:0,         //软装预计金额
                softFirstCost:0,     //软装首期金额
                enable:false
            }
        },

        filters: {
            formatDateTime(value) {
                if (null == value || '' == value) return
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                let h = date.getHours();
                h = h < 10 ? "0" + h : h;
                let m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
                let s = date.getSeconds();
                s = s < 10 ? "0" + s : s;
                // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
                return y + '-' + MM + '-' + d
            }
        },

        methods: {
            removeVideo(index){
                this.videoList.splice(index,1)
            },
            selectSupplierList(index){
              this.index=index
              this.showSupplier=true
            },
            selectSupplierOption(item){
              this.allSoftList[this.softIndex].children[this.softI].roomList[this.index].supplier=item.id
              this.allSoftList[this.softIndex].children[this.softI].roomList[this.index].supplierName=item.supplierName
              this.showSupplier=false
            },
            softBudget(body) {
                let test = 0
                // if (body.roomList) {
                //   for (let k = 0; k < body.roomList.length; k++) {
                //     if (body.roomList[k].budget)
                //       test += Number(body.roomList[k].budget)
                //   }
                // }
                if (body.roomList) {
                    for (let k = 0; k < body.roomList.length; k++) {
                        if (body.roomList[k].budget && body.roomList[k].subType != '10' &&
                            ((body.gacid.toString() == this.broadbandClassification && body.roomList[k].id == -1) ||
                                (body.gacid.toString() == this.smartDevicesClassification &&
                                    ((body.roomList[k].id == -1 &&
                                        ((body.id.toString() == this.smartDevicesLock && this.mensuo == 'N') || (body.id.toString() == this.smartDevicesWatt && this.dianbiao == 'N') || (body.id.toString() == this.smartDevicesGateway))) ||
                                        (body.roomList[k].id != -1 && body.id.toString() == this.smartDevicesInnerLock) ||
                                        (body.id.toString() != this.smartDevicesGateway && body.id.toString() != this.smartDevicesWatt && body.id.toString() != this.smartDevicesLock && body.id.toString() != this.smartDevicesInnerLock))) ||
                                ((body.gacid.toString() != this.broadbandClassification && body.gacid.toString() != this.smartDevicesClassification))))
                            test += Number(body.roomList[k].budget)
                    }
                }
                body.budget = test
                return body.budget
            },

            onRefresh() {
                this.billList = [];
                setTimeout(() => {
                    this.page = 1;
                    this.finished = false
                    this.loading = true
                    this.noContent = false
                    this.isLoading = false
                    this.onLoad();
                }, 500)

            },

            onLoad() {
                let that = this
                let initdata = {}
                //员工id
                initdata.user_id = getStaffId()
                //initdata.staff_id = getStaffId()
                initdata.currentPage = that.page
                initdata.numberPage = 10
                // //租后id 可不填
                initdata.afterRent_id = that.$route.query.arid
                //套房源id 可不填
                if (that.$route.query.setHouse_id)
                    initdata.setHouse_id = that.$route.query.setHouse_id
                browseBillList(initdata).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let list = response.data.data.data
                        // let count = response.data.data.pageCount
                        if (list)
                            that.billList = that.billList.concat(response.data.data.data)
                        that.loading = false
                        if (that.billList.length >= response.data.data.pageCount)
                            that.finished = true
                        if (1 == that.page && (!list || 0 == list.length))
                            that.noContent = true
                        else
                            that.noContent = false
                        that.page++;
                    })
                })
            },

            saveInfo(index) {
                let that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.rtid = that.rtid
                initData.mode = 'save'
                if (0 == index)
                    initData.mode = 'finish'
                initData.hardAppointmentTimeLong = that.ownerInfo.langTime
                initData.softAppointmentTimeLong = that.ownerInfo.startTime
                initData.softchargeGlobaluser_id = that.contractInfo.signatory.id
                initData.softfeePrice = that.softCost
                initData.hardchargeGlobaluser_id = that.contractInfo.partnershipProportion.id
                initData.hardfeePrice = that.hardCost
                initData.hardBeginTime = that.contractInfo.stuffTime
                initData.hardEndTime = new Date(that.endTimes)
                initData.softBeginTime = that.contractInfo.checkInDate
                initData.softEndTime = new Date(that.endingTime)
                initData.hardInpart = that.ownerInfo.firstPeriod / 100
                initData.softInpart = that.ownerInfo.firstPeriods / 100
                initData.firstHardfeePrice = this.isno=='1'?0:that.hardCost * that.ownerInfo.firstPeriod / 100
                initData.firstSoftfeePrice = that.softFirstCost * that.ownerInfo.firstPeriods / 100
                //设计图片
                if (that.contractInfo.attachmentList.length) {
                    initData.pathList = []
                    for (let i = 0; i < that.contractInfo.attachmentList.length; i++)
                        initData.pathList.push({path: that.contractInfo.attachmentList[i].url})
                }
                //视频
                initData.videoList=that.videoList
                initData.roomname='vant'
                that.enable=true
                updateRenovationProgramme(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (200 == response.status) {
                            responseUtil.alertOK(that, '操作成功')
                            that.enable=false
                            if(that.$route.query.location=='detail'){
                              //that.$router.replace('fitmentAuditViewDetail')
                              that.$router.go(-1)
                            }else{
                              that.$router.replace('repairPending')
                            }
                        }
                    })

                })
                that.enable=false
            },

            //物品配置图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];

                        this.afterRead(fileDetail,i,fileLength);
                    }
                }else{
                    this.afterRead(file);
                }
            },

            afterRead(file,i,fileLength) {
                let that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.url = response.data.data.path
                        if(i == fileLength){
                            that.loadingFlag = false
                        }
                    })
                })
            },

            closeSoft() {
                this.allSoftList = this.custody
            },

            closeHard() {
                this.allHardList = this.custody
            },

            hardHave(index) {
                let hardHave = 0
                /*for (let i = 0; i < this.allHardList[index].children.length; i++)
                    if (this.allHardList[index].children[i].count)
                        hardHave++*/
                return hardHave
            },

            softHave(index) {
                let softHave = 0
                /*for (let i = 0; i < this.allSoftList[index].children.length; i++)
                    if (this.allSoftList[index].children[i].count)
                        softHave++*/
                return softHave
            },

            initData() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.rtid = that.rtid
                showProgramme(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data)
                        //9.4新增校验
                        that.broadbandClassification = response.data.data.broadbandClassification
                        that.smartDevicesClassification = response.data.data.smartDevicesClassification
                        that.smartDevicesInnerLock = response.data.data.smartDevicesInnerLock
                        that.smartDevicesLock = response.data.data.smartDevicesLock
                        that.smartDevicesWatt = response.data.data.smartDevicesWatt
                        that.smartDevicesGateway = response.data.data.smartDevicesGateway
                        that.mensuo = response.data.data.mensuo
                        that.dianbiao = response.data.data.dianbiao
                        // 合同详情
                        let contractInfo = response.data.data.contractInfo
                        //地址
                        that.contract.adress = contractInfo.dispatchAddress
                        //合同编号
                        that.contract.contractNo = contractInfo.contractCode
                        //面积
                        that.contract.area = contractInfo.builtUpArea
                        //业主
                        that.contract.theOwner = contractInfo.ownerName
                        //收房年限
                        that.contract.fixedNumberOfYears = contractInfo.contractYears

                        //已有信息
                        let headMap = response.data.data.headMap

                        that.afterRentStatus = headMap.afterRentStatus

                        that.hardDisabled = headMap.hardFinishTime ? true : false
                        that.softDisabled = headMap.softFinishTime ? true : false

                        //申请人
                        that.apply.principal = headMap.applicant
                        //联系方式
                        that.apply.phone = headMap.applicantMobile
                        //费用承担方
                        that.apply.costBearStr = headMap.costBearStr
                        //负责人
                        that.apply.chargeGlobaluserName = headMap.chargeGlobaluserName
                        //设计师
                        that.apply.theDesigner = headMap.designGlobaluserName
                        //装修类型
                        that.apply.housingType = headMap.renovationTypeStr
                        //装修预算
                        that.apply.theBudget = headMap.budget
                        //交房日期
                        that.apply.dataHome = headMap.handoverDate
                        //装修描述
                        that.apply.describe = headMap.afterRentDesprition
                        //装修需求
                        that.apply.demand = headMap.demand
                        //实勘图片
                        if (response.data.data.imgList && response.data.data.imgList.length) {
                            for (let i = 0; i < response.data.data.imgList.length; i++)
                                that.imagesList.push(response.data.data.imgList[i].path)
                            that.$nextTick(() => {
                                if (!that.scroll) {
                                    that.scroll = new BScroll(that.$refs.wrapperBox, {
                                        scrollX: true,
                                        eventPassthrough: 'vertical'
                                    })
                                }
                            })
                        }
                        that.videoList=response.data.data.videoList
                        //房间列表
                        that.roomList = response.data.data.roomList

                        //硬装项目列表
                        that.allHardList = response.data.data.allHardList
                        for (let i = 0; i < that.allHardList.length; i++)
                            for (let j = 0; j < that.allHardList[i].children.length; j++) {
                                that.$set(that.allHardList[i].children[j], 'count', 0)
                                that.$set(that.allHardList[i].children[j], 'budget', 0)
                                that.$set(that.allHardList[i].children[j], 'remarks', '')
                            }
                        //硬装项目列表数据
                        if (response.data.data.handProgrammeList) {
                            let sHM = response.data.data.handProgrammeList
                            for (let a = 0; a < sHM.length; a++)
                                for (let i = 0; i < that.allHardList.length; i++)
                                    if (that.allHardList[i].id == sHM[a].goodsClassId)
                                        for (let j = 0; j < that.allHardList[i].children.length; j++)
                                            if (that.allHardList[i].children[j].id == sHM[a].goodsAllocationId) {
                                                that.allHardList[i].children[j].count = sHM[a].totalCount
                                                that.allHardList[i].children[j].budget = sHM[a].estimateMoney
                                                that.allHardList[i].children[j].remarks = sHM[a].remarks
                                            }
                        }


                        //硬装负责人
                        that.contractInfo.partnershipProportion.userName = headMap.hardChargeName || ''
                        that.contractInfo.partnershipProportion.id = headMap.hardChargeGlobaluser_id || ''
                        //硬装预计开始日期
                        if (headMap.hardAppointmentBeginTime)
                            that.contractInfo.stuffTime = new Date(headMap.hardAppointmentBeginTime)
                        //硬装预计时长
                        that.ownerInfo.langTime = headMap.hardAppointmentTimeLong || ''
                        //硬装首期占比  小数点失精算法修正
                        that.ownerInfo.firstPeriod = parseFloat((headMap.hardFirstPhaseProportion * 100).toPrecision(12))||''
                        that.judgeHardPayment(that.contractInfo.partnershipProportion.id)
                        //软装项目列表
                        that.allSoftList = response.data.data.allSoftList
                        for (let i = 0; i < that.allSoftList.length; i++)
                            for (let j = 0; j < that.allSoftList[i].children.length; j++) {
                                that.$set(that.allSoftList[i].children[j], 'budget', 0)
                                that.$set(that.allSoftList[i].children[j], 'count', 0)
                                that.allSoftList[i].children[j].roomList = JSON.parse(JSON.stringify(response.data.data.roomList))
                                for (let k = 0; k < that.allSoftList[i].children[j].roomList.length; k++) {
                                    that.$set(that.allSoftList[i].children[j].roomList[k], 'count', 0)
                                    that.$set(that.allSoftList[i].children[j].roomList[k], 'budget', 0)
                                    that.$set(that.allSoftList[i].children[j].roomList[k], 'supplier', that.allSoftList[i].children[j].supplier)
                                    that.$set(that.allSoftList[i].children[j].roomList[k], 'supplierName', that.allSoftList[i].children[j].supplierName)
                                    that.$set(that.allSoftList[i].children[j].roomList[k], 'remarks', that.allSoftList[i].children[j].remarks)
                                }
                            }
                        //软装项目列表数据
                        if (response.data.data.softProgrammeList) {
                            let sSM = response.data.data.softProgrammeList
                            for (let a = 0; a < sSM.length; a++)
                                for (let i = 0; i < that.allSoftList.length; i++)
                                    if (that.allSoftList[i].id == sSM[a].goodsClassId)
                                        for (let j = 0; j < that.allSoftList[i].children.length; j++)
                                            if (that.allSoftList[i].children[j].id == sSM[a].goodsAllocationId)
                                                for (let k = 0; k < that.allSoftList[i].children[j].roomList.length; k++)
                                                    if (that.allSoftList[i].children[j].roomList[k].id == sSM[a].roomHouseId) {
                                                        that.allSoftList[i].children[j].roomList[k].count = sSM[a].totalCount
                                                        that.allSoftList[i].children[j].roomList[k].budget = sSM[a].estimateMoney
                                                        that.allSoftList[i].children[j].count += sSM[a].totalCount
                                                        that.allSoftList[i].children[j].budget += sSM[a].estimateMoney
                                                        that.allSoftList[i].children[j].roomList[k].supplier = sSM[a].supplier_id
                                                        that.allSoftList[i].children[j].roomList[k].supplierName = sSM[a].supplierName
                                                        that.allSoftList[i].children[j].roomList[k].remarks=sSM[a].remarks
                                                    }

                        }
                        that.selectedSoftMap=response.data.data.selectedSoftMap
                        that.addSupplier()
                        //软装负责人
                        that.contractInfo.signatory.userName = headMap.softChargeName || ''
                        that.contractInfo.signatory.id = headMap.softChargeGlobaluser_id || ''
                        //软装预计开始日期
                        if (headMap.softAppointmentBeginTime)
                            that.contractInfo.checkInDate = new Date(headMap.softAppointmentBeginTime)
                        //软装预计时长
                        that.ownerInfo.startTime = headMap.softAppointmentTimeLong || ''
                        //软装首期占比
                        that.ownerInfo.firstPeriods = parseFloat((headMap.softFirstPhaseProportion * 100).toPrecision(12)) || ''

                        if (response.data.data.pathList && response.data.data.pathList.length)
                            for (let i = 0; i < response.data.data.pathList.length; i++) {
                                that.contractInfo.attachmentList.push({
                                    content: response.data.data.pathList[i].path,
                                    url: response.data.data.pathList[i].path
                                })
                            }
                    })

                })

            },
            //像allSoftList里添加供应商
            addSupplier(){
            },
            initChargeAndDesignerList: function () {
                var that = this
                let initData = {}
                //initData.user_id= globaluserId()
                initData.userRoleList = ["decorationWorker"]
                returnChargeAndDesignerList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let data = response.data.data
                        //装修工人
                        that.signatoryArr = data.decorationWorker
                    })
                })
            },

            previewImg(start) {
                ImagePreview(
                    {
                        images: this.imagesList,
                        startPosition: start,
                        onClose() {
                            // do something
                        },
                    }
                )
            },


            //返回上一层
            leftReturn() {
                this.$router.go(-1);
            },

            // 数字的加减
            cut(index) {
                if (this.allSoftList[this.softIndex].children[this.softI].roomList[index].count >= 1) {
                    this.allSoftList[this.softIndex].children[this.softI].roomList[index].count--
                    this.allSoftList[this.softIndex].children[this.softI].roomList[index].budget = Number(this.allSoftList[this.softIndex].children[this.softI].roomList[index].budget) - Number(this.allSoftList[this.softIndex].children[this.softI].price)
                    this.allSoftList[this.softIndex].children[this.softI].count--
                    // this.allSoftList[this.softIndex].children[this.softI].budget = Number(this.allSoftList[this.softIndex].children[this.softI].budget) - Number(this.allSoftList[this.softIndex].children[this.softI].price)
                }
            },
            add(index) {
                if (this.allSoftList[this.softIndex].children[this.softI].roomList[index].count >= 0) {
                    this.allSoftList[this.softIndex].children[this.softI].roomList[index].count++
                    this.allSoftList[this.softIndex].children[this.softI].roomList[index].budget = Number(this.allSoftList[this.softIndex].children[this.softI].roomList[index].budget) + Number(this.allSoftList[this.softIndex].children[this.softI].price)
                    this.allSoftList[this.softIndex].children[this.softI].count++
                    // this.allSoftList[this.softIndex].children[this.softI].budget = Number(this.allSoftList[this.softIndex].children[this.softI].budget) + Number(this.allSoftList[this.softIndex].children[this.softI].price)
                }
            },
            changeSoftBudget(index){
                if (this.allSoftList[this.softIndex].children[this.softI].roomList[index].count >= 0) {
                    // this.allSoftList[this.softIndex].children[this.softI].roomList[index].count++
                    this.allSoftList[this.softIndex].children[this.softI].roomList[index].budget = Number(this.allSoftList[this.softIndex].children[this.softI].roomList[index].count) * Number(this.allSoftList[this.softIndex].children[this.softI].price)
                    this.allSoftList[this.softIndex].children[this.softI].count =  Number(this.allSoftList[this.softIndex].children[this.softI].count) + Number(this.allSoftList[this.softIndex].children[this.softI].roomList[index].count)
                    // this.allSoftList[this.softIndex].children[this.softI].budget = Number(this.allSoftList[this.softIndex].children[this.softI].budget) + Number(this.allSoftList[this.softIndex].children[this.softI].price)
                }
            },
            cutTwo() {
                if (this.allHardList[this.hardIndex].children[this.hardI].count >= 1) {
                    this.allHardList[this.hardIndex].children[this.hardI].count--
                    this.allHardList[this.hardIndex].children[this.hardI].budget = Number(this.allHardList[this.hardIndex].children[this.hardI].budget) - Number(this.allHardList[this.hardIndex].children[this.hardI].price)
                }
            },

            changeBudget(){
                if (this.allHardList[this.hardIndex].children[this.hardI].count >= 0) {
                    this.allHardList[this.hardIndex].children[this.hardI].budget = Number(this.allHardList[this.hardIndex].children[this.hardI].count) * (this.allHardList[this.hardIndex].children[this.hardI].price)
                }
            },

            addTwo() {
                if (this.allHardList[this.hardIndex].children[this.hardI].count >= 0) {
                    this.allHardList[this.hardIndex].children[this.hardI].count++
                    this.allHardList[this.hardIndex].children[this.hardI].budget = Number(this.allHardList[this.hardIndex].children[this.hardI].budget) + (this.allHardList[this.hardIndex].children[this.hardI].price)
                }
            },
            //弹窗是否被点击显示
            big(index, i) {
                if (this.softDisabled)
                    return
                this.custody = JSON.parse(JSON.stringify(this.allSoftList))
                this.softIndex = index
                this.softI = i
                this.show = true
            },
            bigTwo(index, i) {
                // if(!this.allHardList[index].children[i].count)
                //     this.$set(this.allHardList[index].children[i],'count',0)
                // if(!this.allHardList[index].children[i].budget)
                //     this.$set(this.allHardList[index].children[i],'budget',0)
                if (this.hardDisabled)
                    return
                this.custody = JSON.parse(JSON.stringify(this.allHardList))
                this.hardIndex = index
                this.hardI = i
                this.showTwo = true
            },

            isTenantInfo() {
                this.whichInfo = '0'
                this.textColors = true
            },

            isContractInfo() {
                this.whichInfo = '1'
            },

            clickCheckInDateShow() {
                this.isCheckInDateShow = !this.isCheckInDateShow;
                // var panel = document.getElementById('checkInDatePanel')
                // if (panel) {
                //   document.addEventListener('click', e => {
                //     if (!panel.contains(e.target)) {
                //       this.isCheckInDateShow = false
                //     }
                //   })
                // }
            },
            clickStuffTimeShow() {
                this.isStuffTimeShow = !this.isStuffTimeShow;
                // var panel = document.getElementById('StuffTime')
                // if (panel) {
                //   document.addEventListener('click', e => {
                //     if (!panel.contains(e.target)) {
                //       this.isStuffTimeShow = false
                //     }
                //   })
                // }
            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value) {
                this.contractInfo.checkInDate = value
                this.isCheckInDateShow = false
            },
            StuffTimeConfirm(value) {
                this.contractInfo.stuffTime = value
                this.isStuffTimeShow = false
            },
            //日期选择组件 点击取消键后执行方法
            checkInDateCancel() {
                this.isCheckInDateShow = false
            },
            StuffTimeCancel() {
                this.isStuffTimeShow = false
            },
            chooseDiscounts(item) {
                this.contractInfo.discounts = item
            },

            //点击下拉框选项 进行数据绑定
            selectSignatoryOption(item, index) {
                if (item) {
                    this.contractInfo.signatory = item
                    this.softChargeIndex = index
                }
                this.isSignatoryShow = false


            },
            //点击下拉框选项 进行数据绑定
            selectPartnershipProportionOption(item, index) {
                if (item) {
                    this.contractInfo.partnershipProportion = item
                    this.hardChargeIndex = index
                }
                this.isPartnershipProportionShow = false
                this.judgeHardPayment(item.id)
            },
            //判断是否为账期
            judgeHardPayment(id){
                if(!id){
                    return
                }
                let that=this
                let initData={}
                initData.id=id
                queryisPament(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.isno=response.data.data.isno

                  })
                })
            },
            getQuerySupplierList(){
                let that=this
                let initData={}
                querySupplierList(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.supplierList=response.data.data.data
                  })
                })
            },
            //获取间和公区
            getQueryRoom(){
                let that=this
                let initData={}
                initData.sethouse_id=that.$route.query.setHouse_id
                initData.ar_id=that.$route.query.arid
                that.softCost=0
                that.softFirstCost=0
                queryRoom(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.roomData=response.data.data.data
                    that.roomCountList=response.data.data.countList
                    that.roomHardData=[]//new Array(that.roomData.length)
                    that.roomSoftData=[]//new Array(that.roomData.length)
                    for(let i=0;i<that.roomData.length;i++){
                           if(that.roomData[i].id=='-1'){
                               that.roomHardData.push('1')
                               that.roomSoftData.push('1')
                           }else{
                               that.roomHardData.push('0')
                               that.roomSoftData.push('0')
                           }
                           if(that.roomData[i].goodsCount){
                               that.softFirstCost+=that.roomData[i].goodsCount
                           }

                    }
                    for(let i in that.roomCountList){
                        for(let j in that.roomData){
                            if((that.roomCountList[i].roomHouse_id==that.roomData[j].id||that.roomData[j].id=='-1')&&that.roomCountList[i].itemType=='0'){
                                // console.log(j)
                               that.roomHardData[j]='1'
                               break
                            }else if((that.roomCountList[i].roomHouse_id==that.roomData[j].id||that.roomData[j].id=='-1')&&that.roomCountList[i].itemType=='1'){
                               that.roomSoftData[j]='1'
                               break
                            }
                        }
                        if(that.roomCountList[i].itemType=='1'){
                           that.softCost+= Number(that.roomCountList[i].money)
                        }
                    }
                  })
                })
            },
            decorateList(e,decorationType){
              console.log(decorationType,e)
              //innerActive
                this.$router.push({
                        name:'decorateList',
                        query:{
                            roomName:e.roomName,
                            decorationType:decorationType,
                            setHouse_id:this.$route.query.setHouse_id,
                            arid:this.$route.query.arid,
                            roomId:e.id
                    }
                })
            },
            //查看业主物业交割
            lookOwner(){

                    this.$router.push({
                        name:'propertyDelivery',
                        query:{
                            contract_id:this.$route.query.contractId,
                            //contractHistoryId:this.contractList[index].contracthistoryId ||'',
                            //type:'2',
                            //ownerOrRenterContractId:this.$route.query.ownerContractId,
                            //historyType:this.contractList[index].contractHistoryHistoryTypeVal,
                            //contractTypeVal:this.$route.query.contractTypeVal,
                            //contractStatus:this.$route.query.contractStatus,
                            //mode:'1'
                        }
                    });
            },
            afterReadTestVideo(file){
                if(file.file.size>5368709120){
                    this.waterMeterFileList5=[]
                    responseUtil.alertMsg(that,'上传视频太大，视频需小于5G')
                    return
                }
                var that=this
                var initData={}
                updateVideo(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.afterReadALi(response.data.data,file.file)
                    })
                })
            },
            afterReadALi(data,file){
                let that=this
                var regionName=data.END_POINT
                let client = new OSS({
                   accessKeyId: data.ACCESS_ID,
                   accessKeySecret: data.ACCESS_KEY,
                   // 填写Bucket名称。
                   bucket: 'himitest',//data.bucketName,//'himitest',
                   region: 'oss-cn-beijing',//regionName.substring(7,21)//'oss-cn-beijing',
                });
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth()+1;
                let fileName = "video/"+yy+'/'+mm+"/"+file.name
                const progress = (p, _checkpoint) => {
                    //that.uploadProgressBar=parseInt(100*p)
                }
                client.multipartUpload(fileName, file,{progress}).then(function (r1) {
                   //that.videourl=r1.res.requestUrls[0].split('?')[0]
                   let img={path:r1.res.requestUrls[0].split('?')[0]}
                   that.videoList.push(img)
                   that.videoData=[]
                }).then(function (r2) {
                }).catch(function (err) {
                    responseUtil.alertMsg(that,'视频上传失败')
               });

            },
            isEmpty(obj) {
                for(var key in obj) {
                    if(obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            },
            roomRegionChange(){
                this.$router.push({
                        name:'mobileSetHouseStructure',
                        query:{
                            // contract_id:this.$route.query.contractId,
                            //contractHistoryId:this.contractList[index].contracthistoryId ||'',
                            //type:'2',
                            //ownerOrRenterContractId:this.$route.query.ownerContractId,
                            //historyType:this.contractList[index].contractHistoryHistoryTypeVal,
                            //contractTypeVal:this.$route.query.contractTypeVal,
                            //contractStatus:this.$route.query.contractStatus,
                            //mode:'1'
                            setHouse_id:this.$route.query.setHouse_id,
                            arid:this.$route.query.arid,
                        }
                    });
            }
        },
        created() {
            console.log(this.$route.query)
            this.initData()
            this.initChargeAndDesignerList()
            this.getQuerySupplierList()
            this.getQueryRoom()
        },
        activated() {
            eventBus.$on('decorationList',function(data) {
                console.log(data)
                if(data=='decorationList'){
                    this.getQueryRoom()
                }

            }.bind(this))
        },
  //如果前往的页面不是推荐人，则摧毁缓存
  beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'decorateList') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  },
    }
</script>

<style scoped lang="less">
    @redius: 8px;
    @border: 1px solid rgb(244, 244, 244);

    @outtab_textPadding: 13px;
    @inertab_textPadding: 15px;
    @text-padding-right: 48px;
    @container_width: 8.8rem;

    .innerTabs {
        position: relative;
        width: @container_width + 0.4;
        margin: 20px auto 15px  auto;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        position: relative;
        font-size: 15px;
        text-align: left;
        //overflow: hidden;
    }

    .van-cell {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 0rem 0.9rem;
        text-align: center;
        overflow: hidden;
        color: #323233;
        font-size: 0.38rem;
        line-height: 52px;
        background-color: #fff;
    }

    .panelBill {
        width: 100%;
        margin: 0px 0px;
        background-color: rgb(255, 255, 255);
        position: relative;
        font-size: 15px;
        text-align: left;
        overflow: hidden;
    }

    .bordBottom {
        border-bottom: 1px solid rgb(244, 244, 244);
    }

    /*css*/
    .part-uploadpic-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        background-color: white;
        padding-top: 8px;
        border-radius: 8px;
    }


    .this-div {
        display: flex;
        align-items: center;
        margin: 2px 15px;
        background-color: white;
        height: 50px;
    }

    .this-div:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .this-div:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .numb {
        position: absolute;
        left: 4px;
        top: 6px;
        font-size: 10px;
        background-color: rgb(0, 0, 0);
        opacity: 0.5;
        color: rgb(255, 255, 255);
        padding: 2px 6px;
        display: inline-block;
        border-radius: 10px;
        font-weight: bolder;
        line-height: 10px;
    }

    .red {
        color: #ff4400;
    }

    .gray {
        color: #C7C7C7;
    }

    .nav-bar {
        background-color: white;
        border: 0;
    }

    .headbar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: 345px;
        font-size: 15px;
        font-weight: 900;
        padding-top: 10px;
        background-color: white;
        -webkit-border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .headbar-fixed {
        background-color: white;
        position: fixed;
        top: 50px;
        z-index: 999;
    }

    .headbar-redline {
        height: 4px;
        width: 20px;
        border-radius: 3px;
        background-color: #ff5d3b;
        margin: 5px 0 0 20px;
    }

    .headbar-graytick {
        display: inline-block;
        /*background: url("../../assets/images/grayTick.png");*/
        width: 18px;
        height: 18px;
        background-size: 18px;
        margin-bottom: -3px;
    }

    .headbar-greentick {
        display: inline-block;
        background: url("../../assets/images/GrassgreenTick.png");
        width: 18px;
        height: 18px;
        background-size: 18px;
        margin-bottom: -3px;
    }

    .contentpart {
        margin-top: -15px;


    }

    .part {
        margin: 15px;
    }

    .other-part {
        margin: 0 15px 50px 15px;
    }

    .other-part-add {
        top: 42px
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        top: 12px;
        margin-left: 15px;

    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-inputpart-houseAddress {
        margin: 50px 15px 20px;
    }

    .part-horizontalLine {
        width: 100%;
        height: 2px;
        background-color: #EDEDED;
        margin-top: 25px;
        margin-bottom: -5px;
    }

    .part-button {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-bottom: 50px;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-hyperlinks {
        float: right;
        color: #999999;
        font-size: 12px;
        margin-top: 10px;
    }

    .part-inputpart div:last-child {
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 0px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 1px;


    }

    .part-inputpart-row-short {
        width: 48%;
        float: left;
    }

    .part-inputpart-row-short-right {
        float: right;
    }

    .part-inputpart-row-short-graytext {
        color: #d8d8d8;
    }

    .part-inputpart-row-header {
        font-weight: 900;
    }

    .part-inputpart-row-sex {
        margin-top: 26px;
    }

    .part-inputpart-row-startPeriod {
        line-height: 21px;
    }

    .part-inputpart-row-endPeriod {
        line-height: 21px;
        padding-left: 10px;
    }

    .part-inputpart-man {
        margin-left: 54px;
        margin-right: 18px;
    }

    .part-inputpart-woman {
        margin-left: 18px;
    }

    .part-inputpart-yes {
        margin-left: 120px;
        margin-right: 18px;
    }

    .part-inputpart-isSupport {
        margin-left: 80px;
        margin-right: 18px;
    }

    .part-inputpart-cardpic {
        margin-top: 15px;
        border-radius: 8px;
        background-color: white;
    }

    .part-inputpart-cardpic-row {
        margin: 0;
    }

    .part-inputpart-cardpic-module {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .part-cardpic-module {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .part-inputpart-cardpic-pic {
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        margin: 0 15px;
    }

    .part-inputpart-cardpic-button {
        display: inline-block;
        background: url("../../assets/images/grayAdd.png") center center no-repeat;
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        background-size: 30px;
        background-color: #fafafa;
        margin: 0 15px;
    }

    .part-inputpart-cardpic-text {
        font-size: 12px;
        color: #d8d8d8;
        margin: 7px 0 13px;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        background-color: white;
        padding-top: 6px;
        border-radius: 8px;
    }

    .part-inputpart-textarea textarea {
        height: 100px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-row input {
        border: 0;
        //width: 100%;
        flex: auto;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .otherExpenses-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-uploadpic-module {
        margin: 10px 5px 10px 5px;
    }

    .part-uploadpic-text {
        font-size: 15px;
        margin-left: 24px;
    }

    .part-uploadpic-module-pic {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        margin-left: 22px;
    }

    .part-inputpart-dropDownList {
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-other-dropDownList-option {
        width: 92%;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .otherExpenses-row-enablenon {
        margin-left: 20px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        //width: 100%;
        flex: auto;
    }

    .part-inputpart-row-normaltext {
        color: black;
        //width: 100%;
        flex: auto;
    }

    .part-inputpart-row-redtext {
        //width: 100%;
        flex: auto;
        color: #ff5d3b;
    }

    .part-inputpart-row-bluetext {
        color: #3590f6;
    }

    .part-inputpart-other-redtext {
        color: #ff5d3b;
    }

    .part-inputpart-row-housetype input {
        width: 18px;
        margin-left: 10px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 10px;
    }

    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }

    .part-inputpart-row-right-downArrow {
        width: 9px;
    }

    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
        margin-top: 1px;


    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .content-divide-position {
        position: relative;
        bottom: 2px;
    }

    .content-bigdivide {
        width: 2px;
        height: 20px;
        background-color: #efefef;
        margin: 0px 55px 10px;
    }

    .content-margedivide {
        width: 3px;
        height: 30px;
        background-color: #efefef;
        margin: 0 10px;
    }

    .bluemiddleLabel {
        width: 46px;
        height: 23px;
        border-radius: 3px;
        color: #6d9ddd;
        background-color: #deebfd;
        text-align: center;
        line-height: 23px;
        margin: 8px;
    }

    .graymiddleLabel {
        width: 46px;
        height: 23px;
        border-radius: 3px;
        background-color: #f5f8fd;
        color: #d1e0f3;
        text-align: center;
        line-height: 23px;
        margin: 8px;
    }

    .redsmallLabel {
        width: 32px;
        height: 14px;
        border-radius: 3px;
        background-color: #fcded7;
        color: #ff5d3b;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        margin: 8px;
    }

    .selectTag {
        display: inline-block;
        background: url("../../assets/images/selectedTag.png");
        width: 23px;
        height: 21px;
        background-size: 20px;
        margin: 10px;
    }

    .noSelectTag {
        display: inline-block;
        background: url("../../assets/images/noSelectedTag.png");
        width: 25px;
        height: 21px;
        background-size: 20px;
        margin: 10px;
    }

    .deleteButton {
        float: left;
        display: inline-block;
        background: url("../../assets/images/deleteButton.png");
        width: 20px;
        height: 20px;
        background-size: 20px;
        margin: 15px 8px 0px 0;
    }

    .selectCircle {
        border: 1px #ff5d3b solid;
        border-radius: 50%;
        padding: 6px;
    }

    .selectCirclePosition {
        position: absolute;
        right: 19px;
    }

    .otherExpenses-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        margin-top: 10px;
    }

    .otherExpenses-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .otherExpenses-inputpart-row input {
        border: 0;
        //width: 100%;
        flex: auto;
    }

    .otherExpenses-price {
        font-weight: 900;
        font-size: 25px;
        margin-right: 20px;
    }

    .otherExpenses-hyperlinks {
        float: right;
        color: #ff5d3b;
        font-size: 14px;
        text-decoration: underline;
        margin-top: 20px;
        position: relative;
        bottom: 10px;
        margin-right: 7px;
        font-weight: 900;
    }

    .part-smalltext {
        font-size: 12px;
        //width: 100%;
        flex: auto;
        font-weight: 900;
    }

    /*详情部分*/
    .details {
        font-size: 12px;
        font-weight: 900;
        color: #F76243;
        margin-bottom: 5px;
        margin-left: 0px;

    }

    .details01 {
        font-size: 12px;
        font-weight: 900;
        color: #F76243;
        padding-top: 20px;
        margin-bottom: 5px;
        margin-left: 15px;

    }

    .details02 {
        font-size: 12px;
        font-weight: 900;
        color: #F76243;
        padding-top: 15px;
        margin-bottom: 10px;
        margin-left: 15px;

    }

    .potential {
        width: 375px;
        background-color: #F8F8F8;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        color: #252525;


        .sub_btn {
            margin: 30px 0 70px;

            .sub_button {
                border-radius: @redius;
                color: rgb(255, 255, 255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

                &.not_choose {
                    background: none;
                    background-color: rgba(184, 184, 184, 0.2);
                }
            }
        }

        p {
            margin: 0px;
        }

        .application_time {
            text-align: right;
            width: 345px;
            font-size: 12px;
            color: rgb(136, 136, 136);
            padding: 2px 0 5px;
        }

        .subtitle {
            padding: 5px 0px;
            color: rgb(244, 99, 76);
            margin-top: 25px;
            font-weight: 700;
            font-size: 12px;
        }

        .overDiv {
            height: 23px;
            /*line-height: 10px;*/
            /*border: 1px solid red;*/
            display: flex;
            align-items: center;
            /*justify-content: center;*/
        }

        /*实际结束更改*/

        .overDiv > div {
            font-weight: 900;
        }

        .overDiv > span {
            font-size: 13px;
            color: rgb(110, 110, 110);
        }

        .calendar_icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url(../../assets/images/timepick.png) no-repeat center center;
            background-size: cover;
            margin-left: 10px;
            background-position-y: -1px;
        }

        .panel {
            width: 100%;
            background-color: rgb(255, 255, 255);
            border-radius: @redius;
            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;


            &.withMargin {
                margin: 15px 0;
            }

            & > p {
                padding: 15px 15px 0px;
                font-weight: 900;
                /*color: #252525;*/

            }

            & > p > span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: 900;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: 900;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;

                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }

            .allprice {
                padding-bottom: 4px;
            }

            /*审核结果,服务人员 审批意见*/

            .shenhe, .fuwu, .yijian {
                font-size: 15px;
            }

            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;
                line-height: 15px;

                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }


        .radio_body {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            .dot {
                display: inline-block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: rgb(136, 136, 136);
                vertical-align: middle;
                margin-right: 10px;
                margin-bottom: 6px;

                &.is_dot {
                    background-color: rgb(251, 91, 64);
                }
            }

            .line {
                width: 2px;
                height: 12px;
                background-color: rgb(250, 250, 250);
            }

            .choose {
                margin-right: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .choose_btn {
                    padding: 5px;
                    margin: 10px;
                    color: rgb(136, 136, 136);
                    cursor: pointer;

                    &.pass {
                        color: rgb(251, 91, 64);
                        font-weight: 900;
                    }

                    &.reject {
                        color: red;
                        font-weight: 900;
                    }
                }
            }
        }


        .t_table {
            .t_header {
                text-align: center;
                color: rgb(136, 136, 136);
                /*font-weight: 900;*/
                font-size: 10px;
                height: 40px;
                line-height: 40px;
            }

            .t_title {
                background-color: rgb(244, 249, 255);
                color: #33ADFF;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                height: 20px;
            }

            .t_td {
                text-align: center;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
            }
        }

        .displayBlock {
            //position: relative;
            overflow: hidden;
            background-color: white;
            border-radius: 9px;
            width: 9rem;
            height: auto;
            margin: 19px auto;
        }

        .swiper_show {
            width: 325px;
            position: relative;
            margin: 15px 10px;
            height: 75px;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 0px;

                .imgWrap {
                    margin-right: 10px;
                }
            }

            /*设计图片部分的css*/

            .part {
                background-color: white;
                border-radius: 8px;
                margin: 15px;

            }


            /*css*/

            .part-subtitle {
                color: #ff5d3b;
                font-size: 12px;
                font-weight: 900;
                position: relative;
                top: 12px;
            }

            /*css*/

            .part-inputpart {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                overflow: hidden;
                font-size: 15px;
            }


            /*css*/

            .part-uploadpic-module {
                margin: 10px 25px 10px 10px;
            }

            /*css*/

            .part-uploadpic-text {
                font-size: 15px;
                margin-left: 24px;
            }

            /*css*/

            .part-uploadpic-module-pic {
                width: 80px;
                height: 80px;
                border-radius: 8px;
            }


            // 图片墙
            .swiper_show01 { //参数
                width: 9rem;
                position: relative;
                margin: 10px 10px;
                height: 78px;

                .content01 {
                    position: absolute;
                    display: flex;
                    justify-content: space-around;
                    left: 0px;

                    .imgWrap01 {
                        margin-right: 10px;
                    }
                }

                .num01 {
                    position: absolute;
                    left: 8px;
                    top: 15px;
                    font-size: 10px;
                    background-color: rgb(0, 0, 0);
                    opacity: .2;
                    color: rgb(255, 255, 255);
                    padding: 2px 6px;
                    display: inline-block;
                    border-radius: 10px;
                    font-weight: 900;
                    line-height: 10px;
                }
            }

            .displayBlock01 { //参数
                //position: relative;
                overflow: hidden;
                background-color: white;
                border-radius: 9px;
                width: 9rem;
                height: auto;
                margin: 19px auto;
            }


            /*tab部分*/
            // 循环表单留着
            .t_table {
                .t_header {
                    text-align: center;
                    color: rgb(136, 136, 136);
                    font-weight: 900;
                    font-size: 10px;
                    height: 40px;
                    line-height: 40px;
                }

                .t_title {
                    background-color: rgb(244, 249, 255);
                    color: rgb(39, 135, 248);
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                }

                .t_td {
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                }

                /*tab关联部分css*/

                .fuck {
                    margin: 15px;
                }

                /*css*/

                .part-inputpart {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 15px;
                }

                .part-inputpart-dropDownList-option-selected {
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    background-color: #ffffff;
                    margin-top: 2px;
                }

                /*参数*/

                .part-inputpart-row-header {
                    font-weight: 900;
                }


                .part-inputpart-row input {
                    border: 0;
                    //width: 100%;
                    flex: auto;
                }


                /*css参数*/

                .part-inputpart-dropDownList {
                    height: 23%;
                    /*垂直方向滚动*/
                    /*overflow-y: scroll;*/
                    overflow: auto;
                    width: 92.5%;
                    position: absolute;
                    z-index: 99;
                }

                /*css参数*/

                .part-inputpart-dropDownList-option {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    line-height: 30px;
                    background-color: #f5f5f5;
                    font-size: 12px;
                    border-bottom: 1px white solid;
                    color: #999999;
                }


                /*参数*/

                .part-inputpart-row-enablenon {
                    margin-left: 26px;
                }

                /*参数*/

                .part-inputpart-row-graytext {
                    color: #d8d8d8;
                    //width: 100%;
                    flex: auto;
                }

                .part-inputpart-row-normaltext {
                    color: black;
                    flex: auto;
                }

                .part-inputpart-row-redtext {
                    //width: 100%;
                    flex: auto;
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row-right {
                    float: right;
                    margin-right: 10px;
                }

                .part-inputpart-row-right-rightArrow {
                    width: 8px;
                }

                .part-inputpart-row-right-downArrow {
                    width: 9px;
                }

                .part-inputpart-row-right-upArrow {
                    width: 9px;
                    transform: rotateX(180deg);
                }

                .part-inputpart-row-right-timepick {
                    width: 20px;
                }

                .content-none {
                    padding: 3px;
                    margin: 0 10px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }

                /*参数*/

                .content-divide {
                    font-size: 12px;
                    color: #efefef;
                    margin: 0 10px;
                }

            }

        }
    }

    /*点击弹出部分的css*/
    .shit { /**/
        width: 100%;
        background-color: rgb(250, 250, 250);
        font-size: 12px;
        box-sizing: border-box;
        border: 1px solid red;
        overflow: hidden;


        .bordBottom-fuck { /**/
            /*border-bottom: 1px solid rgb(244, 244, 244);*/
        }

        .panel-fuck { /**/
            border: 1px solid red;
            width: 345px;
            margin: 0px auto 0px;
            background-color: rgb(255, 255, 255);
            border-radius: 0px;
            position: relative;
            font-size: 15px;
            text-align: left;
            overflow: hidden;


            .info01 {
                &.first {
                    padding: 10px 8px 0px 14px;

                    p {
                        margin: 6px 0;
                        font-weight: 900;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.sec {
                    padding: 0px 14px 0px 14px;

                    p {
                        margin: 6px 0;
                        font-weight: 900;

                        span {
                            font-weight: normal;
                            color: rgb(244, 99, 76);
                        }
                    }
                }

                &.other {
                    padding: 0px 14px 0px 14px;

                    p {
                        margin: 6px 0;
                        font-weight: 900;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.float {
                    padding: 0px 8px 0px 14px;

                    p {
                        margin: 0 0 6px 0;
                        font-weight: 900;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.last {
                    padding: 0px 8px 20px 14px;

                    p {
                        margin: 0 0 6px 0;
                        font-weight: 900;

                        span {
                            font-weight: normal;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                }

                &.footer {
                    padding: 6px 8px 12px 14px;

                    span {
                        font-weight: normal;

                    }

                }
            }

            .panelHeader {
                padding-top: 14px;
            }

            .panelFooter {
                padding-bottom: 14px;
            }

            .panelInfo {
                padding: 0 6px 6px 14px;
                font-size: 14px;

                &.one {
                    width: 42.5%;
                    float: left;
                }

                label {
                    font-weight: 900;

                    .fitment {
                        color: #f00;
                    }

                    span {
                        font-weight: normal;
                        color: rgba(153, 153, 153, 1);
                    }
                }

            }

        }


        // 循环表单
        .fuck_table { /**/

            .t_header {
                text-align: center;
                background-color: #79c5ff;
                color: rgb(136, 136, 136);
                font-weight: normal;
                font-size: 10px;
                height: 20px;
                line-height: 40px;
            }

            .f_title {
                float: left;
                background-color: rgb(244, 249, 255);
                color: rgb(136, 136, 136);
                text-align: center;
                font-weight: 400;
                font-size: 12px;
                margin-top: -12px;
            }

            .f_td {
                text-align: center;
                height: 40px;
                line-height: 40px;
                color: black;
            }
        }


        .deploy-setting .van-collapse-item__header { /**/

        }

        .deploy-setting .van-collapse-item__wrap { /**/
            border-bottom: 0px;
        }

        .collapse-item-title { /**/
            float: left;
            font-weight: 900;
            font-size: 14px;
            color: rgb(244, 99, 76);
        }

        /*编辑图标*/

        .sign-icon {
            margin: 0.32rem 0.48rem;
            width: 0.4rem;
            height: 0.4rem;
            background-image: url("../../assets/images/Editing.png");
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
        }


    }

    /*编辑弹出的部分的css*/
    .popup {
        width: 100%;
    }

    .normalBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        line-height: 1rem;
        /*margin: 19px auto;*/
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 20px 15px 30px 15px;
    }

    /*大窗装配区域的css*/
    .course-boxTop {
        margin-top: 5px;

    }

    .course-box {
        width: 345px;
        height: 100px;
        background-color: white;
        border-radius: 9px;
        margin: 0 20px 30px 15px;
        display: flex;
        flex-wrap: wrap;

        border: none;

        .course-box-item {
            /*width:60px;
    height: 30px;*/
            /*height: 80/75rem;*/
            /*padding-left: 67/75rem;
    padding-right: 67/75rem;
    border: none;

    margin-right: 20/75rem;
    margin-bottom: 20/75rem;
    color: #666666;
    background-color: #C7C7C7;
    font-size: 8px;
    align-items: center;
    display: flex;*/
            float: left;
            color: #DC9382;
            font-size: 12px;
            width: 60px;
            height: 30px;
            background-color: #FFECEC;
            margin: 10px 0 0 17px;
            text-align: center;
            line-height: 33px;
        }

        .secectBackground {
            /*width: 30px;
    height: 20px;*/
            /*height: 80/75rem;*/
            /*padding-left: 67/75rem;
    padding-right: 67/75rem;
    border: none;
    margin-right: 20/75rem;
    margin-bottom: 20/75rem;
    color: #C81623;
    background-color: white;
    font-size: 8px;
    align-items: center;
    display: flex;*/
            float: left;
            color: #F8E5DF;
            font-size: 12px;
            width: 60px;
            height: 30px;
            background-color: #FEF4F2;
            margin: 10px 0 0 17px;
            text-align: center;
            line-height: 33px;

        }
    }


    /*大弹窗样式*/
    .van-popup--bottomOne {
        height: 70%;
        z-index: 2004;
        background-color: #f8f8f8;
    }

    /*小弹窗样式*/
    .van-popup--bottomTwo {
        height: 50%;
        z-index: 2004;
        background-color: #f8f8f8;
    }

    /*项目详情*/
    .titleOne {
        color: #ff5809;
        font-size: 12px;
        font-weight: 900;
        margin: 15px 0 5px 15px;
    }

    /*装配区域*/
    .titleTwo {
        color: #ff5809;
        font-size: 12px;
        font-weight: 900;
        margin: 20px 0 5px 15px;
    }

    /*装配列表区域*/
    .listArea {
        width: 330px;
        height: 100px;
        background-color: white;
        border-radius: 9px;
        margin: 0 20px 30px 15px;
    }

    /*减号样式和加号样式*/
    .numDiv {
        margin: 9px 16px 7px;
        display: inline-block;
        text-align: center;
        height: 30px;
        width: 30px;
        background-color: #f1f1f1;
        line-height: 30px;
    }

    /*减号样式和加号样式*/
    .numDivOtherLeft {
        float: left;
        /*margin: 9px 16px 7px;*/
        /*display: inline-block;*/
        text-align: center;
        height: 30px;
        width: 30px;
        background-color: #f1f1f1;
        line-height: 30px;
    }

    .numDivOtherLeft {
        float: left;
        /*margin: 9px 16px 7px;*/
        /*display: inline-block;*/
        text-align: center;
        height: 30px;
        width: 30px;
        background-color: #f1f1f1;
        line-height: 30px;
    }

    .num {
        font-size: 16px;
        color: #FF5D3B;
        font-weight: 900;
        display: inline-block;
    }

    .num-big {
        text-align: center;
        color: #ff5809;
        font-size: 15.5px;
        float: left;
        margin: 14px 5px 0 5px;
        height: 25px;
        width: 18px;
        line-height: 21px;
    }

    .numCutAndAdd {
        font-size: 24px;

        margin-top: 6px;

    }

    .subtract {
        font-size: 24px;

        margin-top: 4px;

    }

    /*装配区域的房间样式*/
    .room {
        float: left;
        color: #ff5809;
        font-size: 12px;
        width: 60px;
        height: 30px;
        background-color: #FFECEC;
        margin: 15px 0 0 15px;
        text-align: center;
        line-height: 33px;
    }

    .roomLeft {
        float: left;
        color: #ff5809;
        font-size: 12px;
        width: 60px;
        height: 30px;
        background-color: #FFECEC;
        margin: 15px 0 0 24px;
        text-align: center;
        line-height: 33px;
    }

    /*圆点样式---灰色*/
    .maker {
        color: #999999;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 10px;
    }

    /*竖线样式*/
    .content-divide-big {
        font-size: 14px;
        color: #efefef;
        margin: 0 10px;
    }

    /*费用样式*/
    .blockTitle {
        float: left;
        font-size: 15px;
        font-weight: 900;
        white-space: nowrap;
    }

    /*元字样式*/
    .priceUnit {
        color: gray;
        font-size: 14px;
        float: right;
        padding-right: 31px;
    }

    /*费用输入框样式表*/
    .inputPrice {
        //width: 100%;
        flex: auto;
        height: 28px;
        line-height: 28px;
        font-size: 15px;
        font-weight: 900;
        border: none;
    }

    .orangeColor {
        color: #ff3c00;
    }

    /*保存按钮不可点击样式*/
    .saveButton_Disable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 15px 80px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    /*保存按钮可点击样式*/
    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 15px 80px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;

    }

    /*选择名称的div*/
    .part-inputpart-row-big {
        font-weight: 900;
        font-size: 15px;
        float: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 200px;
        height: 50px;
        border-radius: 8px;
        margin: 0 5px 15px 15px;
    }

    .part-inputpart-row-header-big {
        font-weight: 900;
        font-size: 15px;
        white-space: nowrap;
    }

    .part-inputpart-row-graytext-big {
        color: black;
        //width: 100%;
        flex: auto;
        font-size: 15px;
    }

    .part-inputpart-row-right-big {
        float: right;
        margin-right: 10px;
    }

    .part-inputpart-dropDownList-big {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 15px;

    }

    .part-inputpart-dropDownList-option-selected-big {
        color: #ff5d3b;
    }

    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow-big {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-downArrow-big {
        width: 9px;
    }

    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option-big {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }
    .bottom-property{
        float: right;

        width: 120px;
        height: 30px;
        margin:0 10px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-property2{
        float: right;

        width: 120px;
        height: 30px;
        margin:0 10px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,rgb(124, 198, 255),rgb(54, 147, 255));
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
</style>
